import React, { useEffect } from "react";


const Locally = () => {

    useEffect(() => {
        const lcllyScript = document.createElement('script'); 
        lcllyScript.id='lcly-script-0';
        lcllyScript.async = true;
  
        const lcllyScriptConfig = document.createElement('script');
        lcllyScriptConfig.id='lcly-config-0'; 
  
        var lcly_config_0 = {
          "company_name": "Cannon Safe",
          "company_id": "159746",
          "inline": "1",
          "lang": "en-us"
        };
        var lcly_query_0 = Object.keys(lcly_config_0)
        .reduce(function(a,k){a.push(encodeURIComponent(k) + '=' 
        + encodeURIComponent(lcly_config_0[k]));return a},[]).join('&');
        var lcly_endpoint_0 = 'https://cannon-safe.locally.com/stores/map.js?' + lcly_query_0;
        lcllyScript.src = lcly_endpoint_0;
  
        document.body.appendChild(lcllyScript);
        document.body.appendChild(lcllyScriptConfig);
  
    }, [])

    return (
        <div style={{width: '100%'}} id='lcly-button-0'>
        <a
          id='lcly-link-0'
          href='https://brands.locally.com'
          target='_blank'
        >
          Store Locator by Locally
        </a>
      </div>
    )
}

export default Locally;